import React from 'react';

// components
// import DownloadButton from '../components/DownloadButton';
import PillButton from '../components/PillButton';
import SectionHeading from '../components/SectionHeading';
import AppFeatureSingle from '../components/AppFeatureSingle';
import Button from '../components/Button';
import PricingSlider from '../components/PricingSlider';
import ScreenSlider from '../components/ScreenSlider';
import ContactForm1 from '../components/ContactForm1';
import ContactForm2 from '../components/ContactForm2';
import BackgroundAnimation from '../components/BackgroundAnimation';
// import VideoLightBox from '../components/VideoLightBox';
// import TabSection from '../components/TabSection';

// images
import hero_phone from '../../assets/images/hero-phone.png';
// import video_img from '../../assets/images/video-img.jpg';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';
import background_image_2 from '../../assets/images/patterns/pattern-2.jpg';
import feature_phone_1 from '../../assets/images/feature-section-1-phone.png';
import feature_image_1 from '../../assets/images/feature-section-1-img.jpg';
// import feature_phone_2 from '../../assets/images/feature-section-2-phone.png';
// import feature_image_2 from '../../assets/images/feature-section-2-img.jpg';

// data
import features from '../data/features';
import faq from '../data/faq';
// import feature_tab from '../data/featureTab';

const Index = () => {



    return (
        <>
            {/* hero - start */}
            <div className="hero hero-1">
                <div className="hero-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-0 order-lg-1 col-10 offset-1 order-2">
                                <div className="hero-content c-white">
                                    <h1>Energy Analytics powered by Artificial Intelligence</h1>
                                    <p className="large">At Ensight, we are committed to revolutionise the way we use energy. 
                                    Our advanced AI-powered solution goes beyond conventional <b>monitoring systems </b>, 
                                    <b>empowering users</b> with comprehensive insights and efficient management tools 
                                    for seamless integration into the evolving energy landscape. 
                                    </p>
                                    <PillButton buttonType='download-button-2'/>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                                <div className="hero-image">
                                    <img className="drop-shadow" src={hero_phone} alt="hero-phone" />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation/>
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{backgroundImage: `url(${background_image_1})`}}></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* hero - end */}

            {/* app feature - start */}
            <div className="app-feature app-feature-1">
                <div className="app-feature-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    heading='Platform'
                                    subHeading='Faraday System'
                                    icon='las la-cog'
                                    additionalClasses='center width-64'
                                />
                            </div>
                        </div>
                        <div className="row gx-5 gy-5">
                            { features.map((element, key) => {
                                return (
                                    <div className="col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-10 offset-1" key={key}>
                                        <AppFeatureSingle
                                            icon={element.icon}
                                            heading={element.heading}
                                            excerpt={element.excerpt}
                                            containerClass='app-feature-single-2'
                                            link={element.to}
                                        />
                                    </div>
                                );
                            }) }
                        </div>
                    </div>
                </div>
            </div>
            {/* app feature - end */}

            {/* video - start */}
            {/* <div className="video-section">
                <div className="video-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 offset-lg-1 order-lg-1 col-10 offset-1 order-2">
                                <div className="video-section-content">
                                    <div className="section-heading section-heading-1 center-responsive c-white">
                                        <div className="sub-heading upper ls-1">
                                            <i className="las la-video"></i>
                                            <h5>our video</h5>
                                        </div>
                                        <div className="main-heading">
                                            <h1>Benefit from Ensight’s Insight Platform, the innovative energy data analytics platform that helps you derive value from Smart Meter data. </h1>
                                        </div>
                                    </div>
                                    <Button
                                        to="/contact"
                                        content="Get Started"
                                        type="button-1"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 order-lg-2 order-1">
                                <div className="video-section-video">
                                    <figure>
                                        <img className="drop-shadow-1" src={video_img} width='471' height='472' alt="drop-shadow" />
                                        
                                        <div className="play">
                                            <VideoLightBox URL='https://www.youtube.com/watch?v=WIl5F5rM5wQ' />
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* video - end */}
            
            <div className="col-lg-12 offset-lg-0 col-10 offset-1" >
                                <SectionHeading
                                    heading='Software'
                                    subHeading='White-label Products'
                                    icon='las la-cog'
                                    additionalClasses='center width-64'
                                />
                            </div>

            {/* feature section - start */}
            <div className="feature-section feature-section-0 feature-section-spacing-1" style={{marginTop: -60}}>
                <div className="feature-section-wrapper">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-7 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                                <div className="feature-section-content">
                                    <SectionHeading
                                        icon='las la-cog'
                                        heading='app features'
                                        subHeading='Empower your customers'
                                    />
                                    <p className="c-grey">Unlock the advantages of Ensight's Insight Platform, an innovative energy data analytics platform that empowers you to extract maximum value from Smart Meter data.</p>
                                    <div className="icon-text-group">
                                        <div className="icon-text">
                                            <i className="las la-user-lock"></i>
                                            <h4 className="c-dark">Mobile APP</h4>
                                            <p className="c-grey">A mobile app empowers customers to tap into rich insights, providing them with valuable information and deep understanding for enhanced decision-making.</p>
                                        </div>
                                        <div className="icon-text">
                                            <i className="las la-map-marked-alt"></i>
                                            <h4 className="c-dark">Energy Portal </h4>
                                            <p className="c-grey">An energy portal provides users with convenient access to valuable insights, empowering them to make informed decisions and efficiently manage their energy resources.</p>
                                        </div>
                                    </div>
                                    <Button
                                        to="/contact"
                                        content="Get Started"
                                        type="button-2"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                                <div className="feature-section-image">
                                    <img src={feature_image_1} className="image" alt="feature-fore" />
                                    <img src={feature_phone_1} className="phone" alt="phone" />
                                    <div className="background-pattern background-pattern-radius">
                                        <BackgroundAnimation/>
                                        <div className="background-pattern-gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* feature section - end */}




            {/* tab section - start */}
            {/* <div className="testimonial-section">
                <div className="testimonial-section-wrapper" style={{paddingBottom: 80, paddingTop: 40}}>
            <div className="container">
                        <div className="row"></div>
            <TabSection data={feature_tab} subHeading='App Features' />
            </div>
            </div>
            </div> */}
            {/* tab section - end */}


            {/* pricing section - start */}
            <div className="pricing-section">
                <div className="pricing-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-plug'
                                    heading='Get Insights in your consumption with Ensight'
                                    subHeading='Solutions for:'
                                    additionalClasses='center c-white'
                                    mainHeadingClass='c-white'
                                    subHeadingClass='c-white'
                                />
                            </div>
                        </div>
                        <PricingSlider/>
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation/>
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{backgroundImage: `url(${background_image_1})`}}></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* pricing section - end */}


            {/* faq section - start */}
            <div className="faq-section" style={{paddingTop: 70}}>
                <div className="faq-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-10 offset-xxl-1 col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-file-alt'
                                    heading='discover'
                                    subHeading='FAQ'
                                    additionalClasses='center width-64'
                                />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-9 col-md-8 col-10">
                                <div className="faq-wrapper">
                                    <div className="faq" id="faq-accordion">
                                        {
                                            faq.map((element, key) => {
                                                return (
                                                    <div className="accordion-item" key={key}>
                                                        <div className="accordion-header" id={`faq-${key}`}>
                                                            <button
                                                            className={`accordion-button ${key !== 0 ? 'collapsed' : ''}`}
                                                            type="button" 
                                                            data-bs-toggle="collapse" 
                                                            data-bs-target={`#faq-collapse-${key}`} 
                                                            aria-expanded={key === 1 ? 'true': 'false'}
                                                            aria-controls={`faq-collapse-${key}`}>
                                                                <span>{element.question}</span>
                                                            </button>
                                                        </div>
                                                        <div 
                                                        id={`faq-collapse-${key}`} 
                                                        className={`accordion-collapse collapse ${key === 0 ? 'show' : ''}`}
                                                        aria-labelledby={`faq-${key}`} 
                                                        data-bs-parent="#faq-accordion">
                                                            <div className="accordion-body">
                                                                <p>{element.answer}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* faq section - end */}

            {/* screen section - start */}
            <div className="screen-section">
                <div className="screen-section-wrapper">
                    <ScreenSlider/>
                    {/* screen section bottom - start */}
                    <div className="screen-section-bottom">
                        <div className="screen-section-bottom-wrapper">
                            <div className="container">
                                <div className="row">
                                    {/* <div className="col-lg-8 offset-lg-0 col-10 offset-1">
                                        <h1 className="c-white">Download from both Android and iOS</h1>
                                        <h4 className="c-white">
                                            <i className="fas fa-smile" style={{marginRight: '1rem'}}></i>
                                            3 Million users and counting!
                                        </h4>
                                    </div>
                                    <div className="col-lg-4 offset-lg-0 col-10 offset-1">
                                        <DownloadButton groupType='download-button-1-group' buttonType='download-button-1'/>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* screen section bottom - end */}     
                </div>
                <div className="background-pattern background-pattern-2">
                    <BackgroundAnimation/>
                    <div className="background-pattern-gradient"></div>
                    <div className="background-pattern-bottom">
                        <div className="image" style={{backgroundImage: `url(${background_image_2})`}}></div>
                    </div>
                </div>
            </div>
            {/* screem section - end */}

            {/* cta section - start */}
            <div className="cta-section" >
                <div className="cta-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-cloud-download-alt'
                                    heading='Ensight System'
                                    subHeading='Unleash you energy data potential.'
                                    additionalClasses='center width-71'
                                />
                                <PillButton color='c-white'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* cta section - end */}

            {/* contact form section - start */}
            <div className="contact-form-section contact-form-section-1">
                <div className="contact-form-section-wrapper">
                    <div className="container">
                        <div className="row gx-5 contact-form-section-row">
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                {/* contact form - start */}
                                <ContactForm1/>
                                {/* contact form - end */}
                            </div>
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                {/* newsletter form - start */}
                                <ContactForm2/>
                                {/* newsletter form - end */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* contact form section - end */}
        </>
    );
};

export default Index;
