const features = [
    {
        icon: 'las la-lightbulb',
        heading: 'Disaggregation',
        excerpt: 'Energy disaggregation models analyze aggregated data to allocate energy usage patterns to individual appliances or devices.',
        to: '/contact'
    },
    {
        icon: 'las la-headset',
        heading: 'Recommendation System',
        excerpt: 'An energy tips recommendation system provides personalized suggestions and guidance to users for optimizing energy usage and promoting energy efficiency. ',
        to: '/contact'
    },
    {
        icon: 'las la-server',
        heading: 'Custom APIs',
        excerpt: 'An AI chatbot assists customers by providing personalized support, answering queries, and offering solutions to enhance their overall experience. ',
        to: '/contact'
    }
];

export default features;