const policy = [
    // {
    //     icon: "las la-file-invoice",
    //     title: "Terms of Service",
    //     content: (
    //         <>
    //             <p className="paragraph-big">Last updated on May 25, 2022</p>
    //             <h3 className="c-dark">Terms of Service</h3>
    //             <p></p>
    //             <h3 className="c-dark">How does Ensight collect data about me?</h3>
    //             <p>Ensight colelcts data about you:</p>
    //             <ul className="icon-list icon-list-1">
    //                 <li><span>When you browse Ensight.gr.</span></li>
    //                 <li><span>When you use Ensight content delivery networks, or visit websites that do.</span></li>
    //                 <li><span>When you create and use an account on Ensight.gr.</span></li>
    //                 <li><span>When you buy products and services from Ensight through the Ensight.gr web store.</span></li>
    //                 <li><span>When you contact Font Awesome by e-mail for support or other help.</span></li>
    //                 <li><span>Ensight does not buy or otherwise receive data about you from data brokers.</span></li>
    //             </ul>
    //             <p>Ensight does not buy or otherwise receive data about you from data brokers.</p>
    //             <h3 className="c-dark">What data does Ensight collect about me, and why?</h3>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //             <ul className="icon-list icon-list-2">
    //                 <li><span>Optimize the website, so it's quick and easy to use.</span></li>
    //                 <li><span>Diagnose and debug technical errors.</span></li>
    //                 <li><span>Defend the website from abuse and technical attacks.</span></li>
    //                 <li><span>Associate your browsing session with an affiliate, through an affiliate's referral link.</span></li>
    //                 <li><span>Associate your account with an affiliate, if you buy after clicking through.</span></li>
    //                 <li><span>Compile statistics on icon popularity.</span></li>
    //                 <li><span>Compile statistics on the kinds of software and computers visitors use.</span></li>
    //             </ul>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //             <h3 className="c-dark">How can I make choices about data collection?</h3>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //             <ul className="icon-list icon-list-2">
    //                 <li><span>Optimize the website, so it's quick and easy to use.</span></li>
    //                 <li><span>Diagnose and debug technical errors.</span></li>
    //                 <li><span>Defend the website from abuse and technical attacks.</span></li>
    //                 <li><span>Associate your browsing session with an affiliate, through an affiliate's referral link.</span></li>
    //                 <li><span>Associate your account with an affiliate, if you buy after clicking through.</span></li>
    //                 <li><span>Compile statistics on icon popularity.</span></li>
    //                 <li><span>Compile statistics on the kinds of software and computers visitors use.</span></li>
    //             </ul>
    //             <h3 className="c-dark">How can I find out about changes?</h3>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //         </>
    //     )
    // },
    {
        icon: "las la-question-circle",
        title: "Privacy Policy and Personal Data Protection",
        content: (
            <>
                <p className="paragraph-big">Last updated on May 25, 2021</p>
                <h2 className="c-dark">Ensight Ltd</h2>
                <p >Ensight Software Development Ltd fully understands the importance of protecting your personal data. Our goal is to ensure that you feel secure on our website.</p>
                <p >The following Privacy Policy and Personal Data Protection aims to inform you about the type of personal data processed by our company, the purpose of their processing, the categories of recipients, and the procedures that data subjects can follow to exercise their legal rights, in compliance with the strict requirements of Greek Data Protection Law and the EU Regulation 2016/679 on the Protection of Personal Data.</p>
                <p >If you do not agree with the terms of this Policy, as it may be amended, you can discontinue the use of our website.</p>
                <h2 >Definitions</h2>
                <p ><span ><strong><em><span >Personal Data</span></em></strong></span><strong><em>:</em></strong> Any information relating to the data subject, i.e., any natural person to whom the data refers, whose identity is known or can be verified. Examples include name, address, date of birth, gender, mobile phone number, email address, and website usage data, such as IP address.</p>
                <p ><span ><strong><em><span >Data Subject</span></em></strong></span><strong><em>:</em></strong> Natural persons to whom the data refers and whose identity is known to the Company, and who may be clients of the Company.</p>
                <p ><span ><strong><em><span >Processing of Personal Data or Processing:</span></em></strong></span> Any operation or series of operations carried out on personal data, such as collection, recording, organization, maintenance, storage, modification, retrieval, use, transmission, dissemination, or any other form of provision, correlation, or combination, restriction, erasure, or destruction.</p>
                <p ><span ><strong><em><span >Consent of the Data Subject:</span></em></strong></span> Any freely given, specific, informed, and unambiguous indication of the data subject&apos;s wishes by which the data subject, after being informed, agrees to the processing of personal data relating to them. This information includes at least the purpose of processing, the data or categories of data concerned, the recipients or categories of recipients of personal data, as well as the name, surname, and address of the Data Controller and, if applicable, their representative. Consent may be withdrawn at any time without retroactive effect.</p>
                <p ><span ><strong><em><span >Recipient of Data:</span></em></strong></span> Any natural or legal person, public authority, or service or any other entity to whom data are disclosed, whether or not it is a third party.</p>
                <p >Third Party: Any natural or legal person, public authority, or service, or any other organization, other than the data subject, the Data Controller, and the persons authorized to process personal data under the direct authority of the Data Controller.</p>
                <p ><span ><strong><em><span >Data Controller:</span></em></strong></span> Anyone who determines the purpose and manner of processing personal data, such as a natural or legal person, public authority, or service. When the purpose and manner of processing are determined by provisions of law or regulatory provisions of national or EU law, the Data Controller or the specific criteria for selection are determined by national or EU law, respectively.</p>
                <p ><span ><strong><em><span >Data Processor:</span></em></strong></span> Anyone who processes personal data on behalf of the Data Controller, such as a natural or legal person, public authority, or service, or any other organization. Personal Data File (&quot;File&quot;): any structured set of personal data that is accessible based on specific criteria.</p>
                <h3 className="c-dark">Collection of Personal Data and Purpose of Processing</h3>
                <p>Ensight in general an only with active user interaction, collects data that you or your device provide:</p>
                <ul className="icon-list icon-list-1">
                    <li><span>When you browse Ensight.gr</span></li>
                    <li><span>When you use Ensight content delivery networks, or visit websites that do.</span></li>
                    <li><span>When you create and use an account on Ensight.gr</span></li>
                    <li><span>When you buy products and services from Ensight through the Ensight.gr web store.</span></li>
                    <li><span>When you contact Ensight by e-mail for support or other help.</span></li>
                    <li><span>Ensight does not buy or otherwise receive data about you from data brokers.</span></li>
                </ul>
                <p >During your visit and navigation on our company&apos;s website, only the data deemed necessary and appropriate are collected to provide you with an easy and enjoyable browsing experience on our website.</p>
                <p >These collected data include:</p>
                <ul className="icon-list icon-list-1">
                    <li><span>Device information.</span></li>
                    <li><span>Browsing information on our website.</span></li>
                    <li><span>IP address.</span></li>
                    <li><span>Cookie data.</span></li>
                </ul>
                <p >The processing of the above data is carried out solely for the purpose of providing access to our website and ensuring its smooth operation.</p>
                <p >As for the collection of data through our company&apos;s services, data is collected in such a way that your identification is not possible. Therefore, data is collected anonymously and stored in the company&apos;s file. After a certain period, your data is deleted.</p>
                <p >This collection and storage contribute to the training of our algorithms and the improvement of machine learning and artificial intelligence models.</p>
                <p >Specifically,</p>
                <h4 >Collection of Data during Visits</h4>
                <p >You have the option to freely navigate our website without sharing data that can identify you. However, we automatically collect login, navigation, and interaction information with our website to ensure its security. Specifically, in compliance with Article 6(1)(f) of EU Regulation 2016/679, our website collects a range of general data and information upon each of your accesses, which are temporarily stored in log files of a server created as part of the automatic logging by the processing system.</p>
                <h4 >Specifically, we collect the following data:</h4>
                <ul className="icon-list icon-list-2">
                    <li ><span ><strong><em>Browsing Data:</em></strong></span> Information related to your visit to our website, such as the web browser you use, the pages you visited, and the elements you clicked on during your visit.</li>
                    <li ><span ><strong><em>Connection Data:</em></strong></span> Information related to the device you use to navigate and use our website (such as the operating system, browser type, IP address, date and time of access, intermediary server used, recommended location of the IP address, and internet service provider used).</li>
                </ul>
                <p >In general, this information is collected using digital identifiers, such as your device&apos;s unique number, certain browser cookies, or your IP address. These identifiers are used to distinguish between the information collected from your browser or device and the information collected from another user&apos;s browser/device (see also the Cookie Policy).</p>
                <p >The collection and storage of the above data are necessary for the operation of our website to provide functionality and ensure the security of our information technology systems. The legal basis for processing the above data is Article 6(1)(f) of EU Regulation 2016/679.</p>
                <p >In any case, we regularly ensure that the personal data we maintain is up-to-date and accurate. Any of your data collected during your visit or by any other means will be kept for as long as necessary to fulfill the purpose for which it is stored or as required by law.</p>
                <p >To determine the appropriate retention period for your data, we consider the quantity and nature of your data, the potential risk of harm from unauthorized use or disclosure of your data, the purposes for which we process your data, whether we can achieve those purposes through other means, and the applicable legal obligations.</p>
                <h4 >Rights of Data Subjects</h4>
                <p >Under the General Data Protection Regulation, you have the right to be informed about your personal data and the right to access it.</p>
                <p >Additionally, you have the right to restrict processing, rectify, and erase your personal data that concerns you and is subject to processing by us, as stated in this Statement. You also have the right to receive your personal data and transmit it to another data controller without any hindrance from us.</p>
                <p >You may also object to the processing of data for the purposes mentioned at any time and free of charge, separately for each communication channel, and with future effect, including the creation of personalized user profiles.</p>
                <p >For the above purposes, any requests from the data subject should be addressed in writing to our company at the following email: info@ensight.gr</p>
                <p >In the event of your request, we have an obligation to notify you of the correction or deletion of personal data or the restriction of processing under Article 19 of EU Regulation 2016/679.</p>
                <p >We reserve the right to further process if we demonstrate compelling legitimate reasons for processing that override your interests, fundamental rights, and freedoms, or if the processing serves the exercise or defense of legal claims.</p>
                <p >In any case, you have the right to submit your request, to which we are obliged to respond with reasoned justification.</p>
                <p >Following any request, we must respond to you within one month of receiving it. This period may be extended by two further months if deemed necessary due to the complexity of the request and the number of requests. In the event of a delay in responding to the request, we have an obligation to inform you of the reasons for not taking action.</p>
                <p >You have the right to lodge a complaint with the Hellenic Data Protection Authority if you are concerned about how we have processed your personal data. For more details, please visit the website of the Hellenic Data Protection Authority at www.dpa.gr</p>
                <h4 >Cookie Policy for Our Website</h4>
                <p >On our website, we use so-called cookies based on Article 6, paragraph 1, point f of EU Regulation 2016/679. Our interest in optimizing our website is considered legitimate within the meaning of the aforementioned provision.</p>
                <p >Cookies are small files that are stored on your terminal device (laptop, tablet, smartphone, etc.) when you visit our website. Cookies do not harm your terminal device, do not contain viruses, trojans, or other malicious software. Information is stored in the cookie that results in connection with the specific terminal device used. However, this does not mean that we are immediately aware of your identity.</p>
                <p >The use of cookies serves on the one hand to make the use of our website more convenient for you. For this purpose, we use session cookies to recognize that you have already visited individual pages of our website. These are automatically deleted after you leave our site.</p>
                <p >In addition, we also use temporary cookies to optimize user-friendliness, which are stored on your terminal device for a specific period. If you visit our site again to take advantage of our services, we will automatically recognize that you have already been with us and what entries and settings you have made so you do not have to enter them again.</p>
                <p >Please note that you can configure your browser so that cookies are not deposited locally or that already existing cookies are deleted immediately. How this works in detail depends on your browser, please refer to the help function of your browser or contact the manufacturer of your browser software for this purpose.</p>
                <h4 >Data Security</h4>
                <p >Our company implements appropriate technical and organizational measures to protect against the loss, alteration, disclosure, and unauthorized use or access to your data. Furthermore, we limit the collection, processing, and access to your data so that each of our employees processes only the absolutely necessary personal data for the performance of their duties. We ensure that our employees carry out every data processing act in accordance with the guidelines of this policy and commit to maintaining confidentiality.</p>
                <p >In the event of a data breach concerning your personal data, our company will notify you and any relevant authority of any breach as required by law.</p>
                <h4 >Contact Us</h4>
                <p >If you have any questions or complaints regarding this policy or how we handle your personal data, please contact us.</p>
            </>
        )
    },
    // {
    //     icon: "las la-headset",
    //     title: "Get Help",
    //     content: (
    //         <>
    //             <p className="paragraph-big">Last updated on May 25, 2021</p>
    //             <h3 className="c-dark">What is Ensight?</h3>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>

    //             <p>Ensight does not buy or otherwise receive data about you from data brokers.</p>
    //             <h3 className="c-dark">What data does Ensight collect about me, and why?</h3>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //             <ul className="icon-list icon-list-2">
    //                 <li><span>Optimize the website, so it's quick and easy to use.</span></li>
    //                 <li><span>Diagnose and debug technical errors.</span></li>
    //                 <li><span>Defend the website from abuse and technical attacks.</span></li>
    //                 <li><span>Associate your browsing session with an affiliate, through an affiliate's referral link.</span></li>
    //                 <li><span>Associate your account with an affiliate, if you buy after clicking through.</span></li>
    //                 <li><span>Compile statistics on icon popularity.</span></li>
    //                 <li><span>Compile statistics on the kinds of software and computers visitors use.</span></li>
    //             </ul>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //             <h3 className="c-dark">How can I make choices about data collection?</h3>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //             <ul className="icon-list icon-list-2">
    //                 <li><span>Optimize the website, so it's quick and easy to use.</span></li>
    //                 <li><span>Diagnose and debug technical errors.</span></li>
    //                 <li><span>Defend the website from abuse and technical attacks.</span></li>
    //                 <li><span>Associate your browsing session with an affiliate, through an affiliate's referral link.</span></li>
    //                 <li><span>Associate your account with an affiliate, if you buy after clicking through.</span></li>
    //                 <li><span>Compile statistics on icon popularity.</span></li>
    //                 <li><span>Compile statistics on the kinds of software and computers visitors use.</span></li>
    //             </ul>
    //             <h3 className="c-dark">How can I find out about changes?</h3>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //         </>
    //     )
    // },
    {
        icon: "las la-bug",
        title: "Report a Bug",
        content: (
            <>
                <p className="paragraph-big">Last updated on Aug 28, 2023</p>
                <h3 className="c-dark">Found a Bug ?</h3>
                <p>We always value the feedback of your community, partners and users.Therefore if you found a bug , have a suggestion or just want to say "Hi" you can always reach as by email or through our social network accounts</p>
                <h3 className="c-dark">Useful Linkd</h3>
                <ul className="icon-list icon-list-1">
                    <li><span><a href = "mailto: info@ensight.gr">Send Email at &nbsp;  info@ensight.gr</a></span></li>
                    <li><span><a href="tel:+302122134951">Call us at  &nbsp; +30 212 2134 951</a></span></li>
                    <li><span><a class="link-underline" href="https://linkedin.com/company/ensightdata"><i class="fab fa-linkedin-in"></i><span>Linkedin</span></a> </span></li>
                    <li><span><a class="link-underline" href="https://www.youtube.com/channel/UCVT5AZKMNi_KORrC56iIXbA"><i class="fab fa-youtube"></i><span>Youtube</span></a></span></li>
                    <li><span><a class="link-underline" href="https://twitter.com/ensightgr"><i class="fab fa-twitter"></i><span>Twitter</span></a> </span></li>
                </ul>
                <p>Ensight does not buy or otherwise receive data about you from data brokers.</p>
            </>
        )
    } //,
    // {
    //     icon: "las la-user-lock",
    //     title: "Data Protection",
    //     content: (
    //         <>
    //             <p className="paragraph-big">Last updated on May 25, 2021</p>
    //             <h3 className="c-dark">What is Ensight?</h3>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //             <h3 className="c-dark">How does Ensight collect data about me?</h3>
    //             <p>Ensight colelcts data about you:</p>
    //             <ul className="icon-list icon-list-1">
    //                 <li><span>When you browse Ensight.gr.</span></li>
    //                 <li><span>When you use Ensight content delivery networks, or visit websites that do.</span></li>
    //                 <li><span>When you create and use an account on Ensight.gr.</span></li>
    //                 <li><span>When you buy products and services from Ensight through the Ensight.gr web store.</span></li>
    //                 <li><span>When you contact Font Awesome by e-mail for support or other help.</span></li>
    //                 <li><span>Ensight does not buy or otherwise receive data about you from data brokers.</span></li>
    //             </ul>
    //             <p>Ensight does not buy or otherwise receive data about you from data brokers.</p>
    //             <h3 className="c-dark">What data does Ensight collect about me, and why?</h3>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //             <ul className="icon-list icon-list-2">
    //                 <li><span>Optimize the website, so it's quick and easy to use.</span></li>
    //                 <li><span>Diagnose and debug technical errors.</span></li>
    //                 <li><span>Defend the website from abuse and technical attacks.</span></li>
    //                 <li><span>Associate your browsing session with an affiliate, through an affiliate's referral link.</span></li>
    //                 <li><span>Associate your account with an affiliate, if you buy after clicking through.</span></li>
    //                 <li><span>Compile statistics on icon popularity.</span></li>
    //                 <li><span>Compile statistics on the kinds of software and computers visitors use.</span></li>
    //             </ul>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //             <h3 className="c-dark">How can I make choices about data collection?</h3>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //             <ul className="icon-list icon-list-2">
    //                 <li><span>Optimize the website, so it's quick and easy to use.</span></li>
    //                 <li><span>Diagnose and debug technical errors.</span></li>
    //                 <li><span>Defend the website from abuse and technical attacks.</span></li>
    //                 <li><span>Associate your browsing session with an affiliate, through an affiliate's referral link.</span></li>
    //                 <li><span>Associate your account with an affiliate, if you buy after clicking through.</span></li>
    //                 <li><span>Compile statistics on icon popularity.</span></li>
    //                 <li><span>Compile statistics on the kinds of software and computers visitors use.</span></li>
    //             </ul>
    //             <h3 className="c-dark">How can I find out about changes?</h3>
    //             <p>There grass said shall won't thing moved shall sea all male two is. Fowl man sixth gathering heaven was brought air fill dry further abundantly them be from won't every have cattle darkness moveth every the darkness may which evening she'd thing own man is there said gathering be the in lesser called.</p>
    //             <p>Our, that subdue open above creature i forth make and third beast bring had creeping stars brought together. Creepeth creature by open us our appear creeping bring they're darkness may which evening she'd thing days.</p>
    //         </>
    //     )
    // }
];

export default policy;