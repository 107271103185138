import React, { useState } from 'react';
import SectionHeading from './SectionHeading';

const ContactForm1 = () => {

    const [email, setEmail] = useState('');

    return (
        <div className="contact-form contact-form-1 drop-shadow-2">
            <div className="contact-form-wrapper">
                <SectionHeading
                    icon='las la-envelope'
                    heading='subscribe'
                    subHeading='Our newsletter'
                    additionalClasses='section-heading-2 center'
                />
                <div className="contact-form-icon">
                    <i className="las la-envelope-open-text"></i>
                </div>
                <form action="https://ensight.us21.list-manage.com/subscribe/post" method="POST" noValidate>
                    <input type="hidden" name="u" value="ccf8b48e513034263de55ce74"/>
                    <input type="hidden" name="id" value="59b889d1f3"/>
                    <div className="form-floating">
                        <input 
                        className="input form-control" 
                        id="MERGE0" 
                        type="email"
                        name="MERGE0"
                        placeholder="Email address" 
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        />
                        <label htmlFor="MERGE0">Email address</label>
                    </div>
                    <button type="submit" className="button button-2" id="mc-embedded-subscribe">
                        <span className="button-inner">
                            <span className="button-content">
                                <span className="text">Subscribe</span>
                            </span>
                        </span>
                    </button>
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                        <label htmlFor="b_name">Name: </label>
                        <input type="text" name="b_name" tabIndex="-1" defaultValue="" placeholder="Freddie" id="b_name"/>

                        <label htmlFor="b_email">Email: </label>
                        <input type="email" name="b_email" tabIndex="-1" defaultValue="" placeholder="youremail@gmail.com" id="b_email"/>

                        <label htmlFor="b_comment">Comment: </label>
                        <textarea name="b_comment" tabIndex="-1" placeholder="Please comment" id="b_comment"></textarea>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactForm1;
