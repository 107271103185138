const faq = [
    {
        question: 'How does it work ?',
        answer: 'Just like speech consists of words and sounds, each device\'s signature is made up of distinct elements. Ensight gradually learns about your home as things turn on and off. As Ensight tracks your home’s energy, it begins to recognise most appliances and other devices that use more than 60 watts. Typically the Ensight monitor detects devices in the first month after it’s installed and 20-30 devices after 12 months. Each home is different, however, so the number of detected devices may be higher or lower. '
    },
    {
        question: 'What if I don\'t have a smart meter?',
        answer: ' If you don\'t have a smart meter, you can still benefit from the Ensight Platform. We offer alternative methods to collect and analyze your energy consumption data, such as integrating with your utility bills or using plug-in energy monitoring devices that can track your usage. '
    },
    {
        question: 'Can I share my data with my energy supplier? ',
        answer: 'Yes, you have control over your data sharing preferences. The Ensight Platform allows you to choose whether to share your energy consumption data with your energy supplier. You can grant them access to the relevant insights and analytics, fostering better communication and collaboration for efficient energy management.'
    }
];

export default faq;
