import React, { useState } from 'react';
import SectionHeading from './SectionHeading';
import { useForm, ValidationError } from '@formspree/react';

const ContactForm1 = () => {

    // const [state, handleSubmit] = useForm(process.env.REACT_APP_FORM_ID);
    // const [state, handleSubmit] = useForm('moqoqkbw');
    const [state, handleSubmit] = useForm(process.env.REACT_APP_FORM_ID);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    
    if (state.succeeded) {
        return <p>Thanks for Your Message!</p>;
    }

    return (
        <div className="contact-form drop-shadow-2">
            <div className="contact-form-wrapper">
                <SectionHeading
                    icon='las la-envelope'
                    heading='contact'
                    subHeading='Write message'
                    additionalClasses='section-heading-2 center'
                />
                <form method="POST" onSubmit={handleSubmit} style={{paddingTop: 29}}>
                    <div className="form-floating">
                        <input 
                        className="input form-control" 
                        id="name" 
                        type="text"
                        name="name"
                        placeholder="Full name" 
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                        />
                        <label htmlFor="name">Full name</label>
                    </div>
                    <div className="form-floating">
                        <input 
                        className="input form-control" 
                        id="email" 
                        type="email"
                        name="email"
                        placeholder="Email address" 
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        />
                              <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
                        <label htmlFor="email">Email address</label>
                    </div>
                    <div className="form-floating">
                        <input 
                        className="input form-control" 
                        id="message" 
                        type="text"
                        name="message"
                        placeholder="Message" 
                        value={message}
                        onChange={(e) => { setMessage(e.target.value) }}
                        />
                              <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
                        <label htmlFor="message">Message</label>
                    </div>
                    <button type="submit" disabled={state.submitting} className="button button-3">
                        <span className="button-inner">
                            <span className="button-content">
                                <span className="text">Submit Reply</span>
                            </span>
                        </span>
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactForm1;
